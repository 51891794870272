
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "@/presentation/store/enums/StoreEnums";
  import { useI18n } from "vue-i18n";
  import KTFooter from "@/presentation/layout/footer/Footer.vue";

  export default defineComponent({
    name: "auth",
    components: {
      KTFooter,
    },
    setup() {
      const { t } = useI18n();
      const store = useStore();
  
      onMounted(() => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
      });
  
      onUnmounted(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
      });
    },
  });
  