<template>
    <div
      class="
        d-flex
        flex-column flex-column-fluid
        bgi-position-y-bottom
        position-x-center
        bgi-size-contain bgi-attachment-fixed
      "
      style="background-image: url('/media/background/wp.png')"
    >
      <!--begin::Content-->
      <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
        <!--begin::Logo-->
        <a href="https://ogzatech.com/tr/" class="mb-6">
          <img alt="Logo" src="/media/logos/logo.png" class="h-100px" />
        </a>
        <!--end::Logo-->
  
        <router-view></router-view>
      </div>
      <!--end::Content-->
      <KTFooter></KTFooter>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent, onMounted, onUnmounted } from "vue";
  import { useStore } from "vuex";
  import { Actions } from "@/presentation/store/enums/StoreEnums";
  import { useI18n } from "vue-i18n";
  import KTFooter from "@/presentation/layout/footer/Footer.vue";

  export default defineComponent({
    name: "auth",
    components: {
      KTFooter,
    },
    setup() {
      const { t } = useI18n();
      const store = useStore();
  
      onMounted(() => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
      });
  
      onUnmounted(() => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
      });
    },
  });
  </script>
  